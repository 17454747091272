<template>
  <div class="container mt-4">
    <div style="background-color: #007bff; color: #fff; padding: 10px">
      <h4 style="margin: 0">Liste des logs</h4>
    </div>
    <div style="padding: 10px">
      <div style="margin-bottom: 10px">
        <input
          type="text"
          style="padding: 5px; border-radius: 5px; border: none; width: 200px"
          placeholder="Recherche..."
          v-model="search"
        />
        <button
          style="
            padding: 5px;
            border-radius: 5px;
            border: none;
            background-color: #fff;
          "
        >
          Rechercher
        </button>
      </div>
      <div class="table-responsive">
        <table
          style="
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #007bff;
          "
        >
          <thead style="background-color: #007bff; color: #fff">
            <tr>
              <th scope="col" style="padding: 10px; border: 1px solid #007bff">
                Date
              </th>
              <th scope="col" style="padding: 10px; border: 1px solid #007bff">
                Événement
              </th>
              <th scope="col" style="padding: 10px; border: 1px solid #007bff">
                Nom utilistaeur
              </th>
              <th scope="col" style="padding: 10px; border: 1px solid #007bff">
                Numéro de ticket
              </th>
              <th scope="col" style="padding: 10px; border: 1px solid #007bff">
                Revendeur du ticket
              </th>
              <!-- add more table headers for each field in the logSchema -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(log, index) in filteredLogs"
              :key="index"
              style="border: 1px solid #007bff"
            >
              <td style="padding: 10px; border: 1px solid #007bff">
                {{ new Date(log.date).toLocaleString() }}
              </td>
              <td style="padding: 10px; border: 1px solid #007bff">
                {{ log.event }}
              </td>
              <td style="padding: 10px; border: 1px solid #007bff">
                {{ log.userName }}
              </td>
              <td style="padding: 10px; border: 1px solid #007bff">
                {{ log.ticket.numero }}
              </td>
              <td style="padding: 10px; border: 1px solid #007bff">
                {{ log.ticket.revendeur }}
              </td>
              <!-- add more table cells for each field in the logSchema -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/axios";

export default {
  data() {
    return {
      logs: [],
      search: "",
    };
  },
  mounted() {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css";
    document.head.appendChild(link);

    HTTP.get("logs")
      .then((response) => {
        console.log("Hello" + response.data);
        this.logs = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    filteredLogs() {
      return this.logs.filter((log) => {
        return log.ticket.numero
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
};
</script>

import { render, staticRenderFns } from "./voir_facture.vue?vue&type=template&id=40d68364&scoped=true&"
import script from "./voir_facture.vue?vue&type=script&lang=js&"
export * from "./voir_facture.vue?vue&type=script&lang=js&"
import style0 from "./devie.scss?vue&type=style&index=0&id=40d68364&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d68364",
  null
  
)

export default component.exports